<div class="c-pad custom-component-theme">
  <div class="page-header">
    <div class="c-header">
      <div class="c-title">
        <svg
          width="25"
          height="21"
          viewBox="0 0 25 21"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M24.375 8.625C24.6875 8.625 25 8.9375 25 9.25V10.5C25 10.8516 24.6875 11.125 24.375 11.125H21.875V13.625C21.875 13.9766 21.5625 14.25 21.25 14.25H20C19.6484 14.25 19.375 13.9766 19.375 13.625V11.125H16.875C16.5234 11.125 16.25 10.8516 16.25 10.5V9.25C16.25 8.9375 16.5234 8.625 16.875 8.625H19.375V6.125C19.375 5.8125 19.6484 5.5 20 5.5H21.25C21.5625 5.5 21.875 5.8125 21.875 6.125V8.625H24.375ZM8.75 10.5C5.97656 10.5 3.75 8.27344 3.75 5.5C3.75 2.76562 5.97656 0.5 8.75 0.5C11.4844 0.5 13.75 2.76562 13.75 5.5C13.75 8.27344 11.4844 10.5 8.75 10.5ZM12.2266 11.75C15.1172 11.75 17.5 14.1328 17.5 17.0234V18.625C17.5 19.6797 16.6406 20.5 15.625 20.5H1.875C0.820312 20.5 0 19.6797 0 18.625V17.0234C0 14.1328 2.34375 11.75 5.23438 11.75H5.89844C6.75781 12.1797 7.73438 12.375 8.75 12.375C9.76562 12.375 10.7031 12.1797 11.5625 11.75H12.2266Z"
            fill="var(--customizable-primary-color)"
          />
        </svg>

        <span class="title">{{ "NEW_ATHLETE" | transloco }}</span>
      </div>
      <span class="descr">{{ "NEW_ATHLETE_DESC" | transloco }}</span>
    </div>

    <img src="assets/img/aau-logo.png" class="logo-header" />
  </div>
  <div class="flex-row-large">
    <div class="first-column">
      <form [formGroup]="athleteForm" (ngSubmit)="onSubmit()" class="c-form">
        <div class="flex-column">
          <span class="section-title margin-b-16">
            {{ "NEW_ATHLETE_DETAILS" | transloco }}</span
          >

          <div class="radio-hover f-column">
            <label class="radio-group-label">{{
              "NEW_ATHLETE_SIGNING_UP" | transloco
            }}</label>
            <mat-radio-group formControlName="signingUpAs" class="custom-radio">
              <mat-radio-button
                value="myself"
                class="m-r-20"
                [disableRipple]="true"
                >{{ "NEW_ATHLETE_OPTION_MYSELF" | transloco }}</mat-radio-button
              >
              <mat-radio-button
                value="myChild"
                class="m-r-20"
                [disableRipple]="true"
                >{{
                  "NEW_ATHLETE_OPTION_MY_CHILD" | transloco
                }}</mat-radio-button
              >
              <mat-radio-button
                value="someoneElse"
                class="m-r-20"
                [disableRipple]="true"
                >{{
                  "NEW_ATHLETE_OPTION_SOMEONE_ELSE" | transloco
                }}</mat-radio-button
              >

              <mat-error
                *ngIf="
                  athleteForm.get('signingUpAs')?.hasError('required') &&
                  (athleteForm.get('signingUpAs')?.touched || submitted)
                "
              >
                {{ "REQUIRED" | transloco }}
              </mat-error>
            </mat-radio-group>
          </div>
          <span class="info-warning" *ngIf="infoLabel">{{
            infoLabel | transloco
          }}</span>
        </div>

        <div class="flex-column-g-32" *ngIf="infoLabel">
          <app-custom-searchable-select
            *ngIf="sports && sports.length > 0"
            [label]="'NEW_ATHLETE_PRIMARY_SPORT'"
            [items]="sports"
            class="m-w-400"
            [selectedItem]="athleteForm.get('primarySport')?.value"
            (itemSelected)="selectedSport($event)"
            [error]="
              athleteForm.get('primarySport')?.hasError('required') &&
              (athleteForm.get('primarySport')?.touched || submitted)
            "
          >
          </app-custom-searchable-select>
          <mat-error
            class="custom-m-error"
            *ngIf="
              athleteForm.get('primarySport')?.hasError('required') &&
              (athleteForm.get('primarySport')?.touched || submitted)
            "
          >
            {{ "REQUIRED" | transloco }}
          </mat-error>

          <div class="row-gap-32">
            <mat-form-field appearance="fill" class="m-w-260">
              <mat-label>{{ "FIRST_NAME" | transloco }}</mat-label>
              <input
                matInput
                formControlName="firstName"
                (input)="updateFormWithEvent('firstName', $event)"
              />
              <mat-error
                *ngIf="
                  athleteForm.get('firstName')?.hasError('required') &&
                  (athleteForm.get('firstName')?.touched || submitted)
                "
              >
                {{ "REQUIRED" | transloco }}
              </mat-error>
            </mat-form-field>

            <!-- <app-custom-material-input
              [value]="athleteForm.get('firstName')?.value"
              [label]="'FIRST_NAME'"
              class="m-w-260"
              (valueChanged)="updateForm('firstName', $event)"
              [error]="
                athleteForm.get('firstName')?.hasError('required') &&
                (athleteForm.get('firstName')?.touched || submitted)
                  ? 'REQUIRED'
                  : ''
              "
            ></app-custom-material-input> -->

            <app-custom-material-input
              [value]="middleName"
              [label]="'NEW_ATHLETE_MIDDLE_NAME'"
              class="m-w-260 middlename"
              (valueChanged)="updateForm('middleName', $event)"
              [error]="
                middleNameRequired &&
                iHaveMiddleName &&
                !(athleteForm.get('middleName')?.value?.length > 0) &&
                (athleteForm.get('middleName')?.touched || submitted)
                  ? ' '
                  : ''
              "
            ></app-custom-material-input>
            <div
              *ngIf="middleNameRequired"
              class="m-w-260 i-do-not-have-mobile"
            >
              <mat-error
                class="special-required"
                *ngIf="
                  middleNameRequired &&
                  iHaveMiddleName &&
                  !(athleteForm.get('middleName')?.value?.length > 0) &&
                  (athleteForm.get('middleName')?.touched || submitted)
                "
              >
                {{ "REQUIRED" | transloco }}
              </mat-error>

              <span (click)="onCheckboxChange()" class="c-check">
                <svg
                  *ngIf="iHaveMiddleName"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <rect
                    x="1"
                    y="1"
                    width="14"
                    height="14"
                    rx="1"
                    stroke="#9A9A9A"
                    stroke-width="2"
                  />
                </svg>
                <svg
                  *ngIf="!iHaveMiddleName"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="2"
                    fill="var(--customizable-primary-color)"
                  />
                  <path
                    d="M3 9L6 12L13 5"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                {{ "I_DO_NOT_HAVE_A_MIDDLE_NAME" | transloco }}
              </span>
            </div>
          </div>

          <div class="row-gap-32">
            <app-custom-material-input
              [value]="athleteForm.get('lastName')?.value"
              [label]="'LAST_NAME'"
              class="m-w-260"
              (valueChanged)="updateForm('lastName', $event)"
              [error]="
                athleteForm.get('lastName')?.hasError('required') &&
                (athleteForm.get('lastName')?.touched || submitted)
                  ? 'REQUIRED'
                  : ''
              "
            ></app-custom-material-input>

            <div *ngIf="middleNameRequired" class="m-w-260 i-do-not-have">
              <mat-error
                class="special-required"
                *ngIf="
                  middleNameRequired &&
                  iHaveMiddleName &&
                  !(athleteForm.get('middleName')?.value?.length > 0) &&
                  (athleteForm.get('middleName')?.touched || submitted)
                "
              >
                {{ "REQUIRED" | transloco }}
              </mat-error>

              <span (click)="onCheckboxChange()" class="c-check">
                <svg
                  *ngIf="iHaveMiddleName"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <rect
                    x="1"
                    y="1"
                    width="14"
                    height="14"
                    rx="1"
                    stroke="#9A9A9A"
                    stroke-width="2"
                  />
                </svg>
                <svg
                  *ngIf="!iHaveMiddleName"
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <rect
                    width="16"
                    height="16"
                    rx="2"
                    fill="var(--customizable-primary-color)"
                  />
                  <path
                    d="M3 9L6 12L13 5"
                    stroke="white"
                    stroke-width="1.5"
                    stroke-linecap="round"
                  />
                </svg>
                {{ "I_DO_NOT_HAVE_A_MIDDLE_NAME" | transloco }}
              </span>
            </div>
          </div>

          <div class="row-gap-40">
            <app-custom-date-picker
              [selectedDate]="dateOfBirth"
              class="w-225"
              [submitted]="submitted"
              (dateSelected)="handleDateSelected($event)"
              [error]="dateOfBirthError"
            ></app-custom-date-picker>

            <div class="flex-column radio-hover">
              <label class="radio-group-label">{{
                "NEW_ATHLETE_BIRTH_GENDER" | transloco
              }}</label>
              <mat-radio-group formControlName="birthGender">
                <mat-radio-button
                  value="MALE"
                  class="m-r-20"
                  [disableRipple]="true"
                  >{{ "MALE" | transloco }}</mat-radio-button
                >
                <mat-radio-button
                  value="FEMALE"
                  class="m-r-20"
                  [disableRipple]="true"
                  >{{ "FEMALE" | transloco }}</mat-radio-button
                >

                <mat-error
                  class="error-birth"
                  *ngIf="
                    athleteForm.get('birthGender')?.hasError('required') &&
                    (athleteForm.get('birthGender')?.touched || submitted)
                  "
                >
                  {{ "REQUIRED" | transloco }}
                </mat-error>
              </mat-radio-group>
            </div>
          </div>

          <div class="row-gap-40">
            <div
              class="extrict-w-225"
              *ngIf="countryNames && countryNames.length > 0"
            >
              <app-custom-searchable-select
                [label]="'COUNTRY'"
                [items]="countryNames"
                class="min-and-max-w-100"
                [selectedItem]="selectedCountryName"
                (itemSelected)="selectCountry($event)"
              >
              </app-custom-searchable-select>
              <mat-error
                *ngIf="
                  athleteForm.get('country')?.hasError('required') &&
                  (athleteForm.get('country')?.touched || submitted)
                "
              >
                {{ "REQUIRED" | transloco }}
              </mat-error>
            </div>

            <app-custom-material-input
              [value]="athleteForm.get('postalCode')?.value"
              [label]="'POSTAL_CODE'"
              class="m-w-180"
              (valueChanged)="updateForm('postalCode', $event)"
              [error]="
                athleteForm.get('postalCode')?.hasError('required') &&
                (athleteForm.get('postalCode')?.touched || submitted)
                  ? 'REQUIRED'
                  : athleteForm
                      .get('postalCode')
                      ?.hasError('invalidPostalCode') &&
                    (athleteForm.get('postalCode')?.touched || submitted)
                  ? 'INVALID_POSTAL_CODE_COUNTRY'
                  : ''
              "
            ></app-custom-material-input>
          </div>

          <app-custom-material-input
            [value]="athleteForm.get('homeAddress')?.value"
            [label]="'NEW_ATHLETE_HOME_ADDRESS'"
            class="m-w-384"
            (valueChanged)="updateForm('homeAddress', $event)"
            [error]="
              athleteForm.get('homeAddress')?.hasError('required') &&
              (athleteForm.get('homeAddress')?.touched || submitted)
                ? 'REQUIRED'
                : ''
            "
          ></app-custom-material-input>

          <div class="row-gap-20">
            <mat-form-field
              appearance="fill"
              class="m-w-150"
              *ngIf="graduationYears && graduationYears.length > 0"
            >
              <mat-label>{{
                "NEW_ATHLETE_GRADUATION_YEAR" | transloco
              }}</mat-label>
              <mat-select
                formControlName="graduationYear"
                #selectGradYear
                panelClass="custom-panel"
              >
                <mat-option
                  *ngFor="let year of graduationYears"
                  [value]="year"
                  >{{ year }}</mat-option
                >
              </mat-select>

              <svg
                matSuffix
                xmlns="http://www.w3.org/2000/svg"
                class="custom-chevron"
                [class.rotate]="selectGradYear.panelOpen"
                width="9"
                height="10"
                viewBox="0 0 9 10"
                fill="none"
              >
                <path
                  d="M4.84021 7.3724L8.86082 3.7051C9.04639 3.51607 9.04639 3.23251 8.86082 3.06238L8.3866 2.6276C8.20103 2.45747 7.89175 2.45747 7.68557 2.6276L4.48969 5.53875L1.31443 2.6276C1.10825 2.45747 0.798969 2.45747 0.613402 2.6276L0.139175 3.06238C-0.0463918 3.23251 -0.0463918 3.51607 0.139175 3.7051L4.15979 7.3724C4.34536 7.54253 4.65464 7.54253 4.84021 7.3724Z"
                  fill="#7A7A7A"
                />
              </svg>
            </mat-form-field>
          </div>

          <div class="separator"></div>

          <span class="section-title">
            {{ "NEW_ATHLETE_CLUB_DETAILS" | transloco }}</span
          >

          <app-custom-searchable-select
            *ngIf="clubs && clubs.length > 0"
            [label]="'NEW_ATHLETE_CLUB'"
            [items]="clubs"
            [selectedItem]="selectedClub"
            class="w-100"
            (itemSelected)="selectClub($event)"
            [error]="
              athleteForm.get('clubDetails')?.hasError('required') &&
              (athleteForm.get('clubDetails')?.touched || submitted)
            "
          >
          </app-custom-searchable-select>
          <span class="note">{{ "NEW_ATHLETE_CLUB_NOTE" | transloco }}</span>

          <div class="separator"></div>

          <span class="section-title">
            {{
              (myself ? "CONTACT_DETAILS" : "NEW_ATHLETE_GUARDIAN_DETAILS")
                | transloco
            }}</span
          >

          <div class="row-gap-32" *ngIf="!myself">
            <app-custom-material-input
              [value]="athleteForm.get('guardianfirstName')?.value"
              [label]="'FIRST_NAME'"
              class="m-w-260"
              (valueChanged)="updateForm('guardianfirstName', $event)"
              [error]="
                athleteForm.get('guardianfirstName')?.hasError('required') &&
                (athleteForm.get('guardianfirstName')?.touched || submitted)
                  ? 'REQUIRED'
                  : ''
              "
            ></app-custom-material-input>

            <app-custom-material-input
              [value]="athleteForm.get('guardianLastName')?.value"
              [label]="'LAST_NAME'"
              class="m-w-260"
              (valueChanged)="updateForm('guardianLastName', $event)"
              [error]="
                athleteForm.get('guardianLastName')?.hasError('required') &&
                (athleteForm.get('guardianLastName')?.touched || submitted)
                  ? 'REQUIRED'
                  : ''
              "
            ></app-custom-material-input>
          </div>

          <app-custom-material-input
            [value]="athleteForm.get('guardianEmail')?.value ?? email"
            [label]="'EMAIL_ADDRESS'"
            class="m-w-360"
            (valueChanged)="updateForm('guardianEmail', $event)"
            [error]="
              athleteForm.get('guardianEmail')?.hasError('required') &&
              (athleteForm.get('guardianEmail')?.touched || submitted)
                ? 'REQUIRED'
                : ''
            "
          ></app-custom-material-input>

          <app-custom-material-input
            [value]="athleteForm.get('comfirmGuardianEmail')?.value ?? email"
            [label]="'CONFIRM_EMAIL_ADDRESS'"
            class="m-w-360"
            (valueChanged)="updateForm('comfirmGuardianEmail', $event)"
            [error]="
              athleteForm.get('comfirmGuardianEmail')?.hasError('required') &&
              (athleteForm.get('comfirmGuardianEmail')?.touched || submitted)
                ? 'REQUIRED'
                : ''
            "
            [imageUrl]="isConfirmEmailEqual ? 'assets/icons/tickok.svg' : ''"
          >
          </app-custom-material-input>

          <div class="row-gap-0">
            <app-custom-searchable-select
              *ngIf="countries && countries.length > 0"
              [label]="'PHONE_NUMBER'"
              [items]="countries"
              class="min-max-w-120"
              (itemSelected)="changePrefixCountry($event)"
              [selectedItem]="selectedCountry"
            >
            </app-custom-searchable-select>

            <app-custom-material-input
              [value]="phone"
              [label]="'emptyLabel'"
              class="m-w-150 extra-mt"
              (valueChanged)="updateForm('phoneNumber', $event)"
              [applyPhoneMask]="true"
            >
            </app-custom-material-input>
          </div>

          <div class="separator"></div>
          <ng-container
            *ngIf="!(isLargeScreen | async); then imageContainer"
          ></ng-container>

          <div class="separator"></div>

          <app-ncsa-banner
            *ngIf="showShareInformation"
            [yes]="isWantsNCSARecruiting"
            class="w-100 margin-b-32"
            (choose)="shareWithNcsa($event)"
          ></app-ncsa-banner>

          <span class="save-and-add" (click)="onSubmit(true)">
            <svg
              class="save-and-add-icon"
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="12"
              viewBox="0 0 12 12"
              fill="none"
            >
              <path
                d="M11.1429 4.71429C11.5982 4.71429 12 5.11607 12 5.57143V6.42857C12 6.91071 11.5982 7.28571 11.1429 7.28571H7.28571V11.1429C7.28571 11.625 6.88393 12 6.42857 12H5.57143C5.08929 12 4.71429 11.625 4.71429 11.1429V7.28571H0.857143C0.375 7.28571 0 6.91071 0 6.42857V5.57143C0 5.11607 0.375 4.71429 0.857143 4.71429H4.71429V0.857143C4.71429 0.401786 5.08929 0 5.57143 0H6.42857C6.88393 0 7.28571 0.401786 7.28571 0.857143V4.71429H11.1429Z"
                fill="var(--customizable-primary-color)"
              />
            </svg>
            {{ "NEW_ATHLETE_SAVE_AND_ADD" | transloco }}
          </span>

          <div class="form-buttons">
            <button type="button" class="cancel" (click)="goBack()">
              {{ "CANCEL" | transloco }}
            </button>
            <button type="submit" class="save">
              {{ "NEW_ATHLETE_SAVE_ATHLETE" | transloco }}
            </button>
          </div>
        </div>
      </form>
    </div>

    <div
      class="second-column"
      *ngIf="infoLabel && (isLargeScreen | async); then imageContainer"
    ></div>
  </div>
</div>

<ng-template #imageContainer>
  <app-img-crop-input-field
    [imageUrl]="editingPerson?.photoUrl || selfPerson?.photoUrl || undefined"
    (imageSelected)="updateImgForm($event)"
  ></app-img-crop-input-field>
</ng-template>
