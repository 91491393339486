<div class="c-pad custom-component-theme">
  <div class="header">
    <div class="left-header">
      <div class="title-section">
        <svg
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.125 18.25H1.875C0.820312 18.25 0 17.4297 0 16.375V2.625C0 1.60938 0.820312 0.75 1.875 0.75H18.125C19.1406 0.75 20 1.60938 20 2.625V16.375C20 17.4297 19.1406 18.25 18.125 18.25ZM5 4.1875C4.10156 4.1875 3.4375 4.89062 3.4375 5.75C3.4375 6.64844 4.10156 7.3125 5 7.3125C5.85938 7.3125 6.5625 6.64844 6.5625 5.75C6.5625 4.89062 5.85938 4.1875 5 4.1875ZM5 7.9375C4.10156 7.9375 3.4375 8.64062 3.4375 9.5C3.4375 10.3984 4.10156 11.0625 5 11.0625C5.85938 11.0625 6.5625 10.3984 6.5625 9.5C6.5625 8.64062 5.85938 7.9375 5 7.9375ZM5 11.6875C4.10156 11.6875 3.4375 12.3906 3.4375 13.25C3.4375 14.1484 4.10156 14.8125 5 14.8125C5.85938 14.8125 6.5625 14.1484 6.5625 13.25C6.5625 12.3906 5.85938 11.6875 5 11.6875ZM16.25 6.375V5.125C16.25 4.89062 16.0156 4.65625 15.7812 4.65625H7.96875C7.69531 4.65625 7.5 4.89062 7.5 5.125V6.375C7.5 6.64844 7.69531 6.84375 7.96875 6.84375H15.7812C16.0156 6.84375 16.25 6.64844 16.25 6.375ZM16.25 10.125V8.875C16.25 8.64062 16.0156 8.40625 15.7812 8.40625H7.96875C7.69531 8.40625 7.5 8.64062 7.5 8.875V10.125C7.5 10.3984 7.69531 10.5938 7.96875 10.5938H15.7812C16.0156 10.5938 16.25 10.3984 16.25 10.125ZM16.25 13.875V12.625C16.25 12.3906 16.0156 12.1562 15.7812 12.1562H7.96875C7.69531 12.1562 7.5 12.3906 7.5 12.625V13.875C7.5 14.1484 7.69531 14.3438 7.96875 14.3438H15.7812C16.0156 14.3438 16.25 14.1484 16.25 13.875Z"
            fill="var(--customizable-primary-color)"
          />
        </svg>

        <span class="title">{{ "NAV_ROSTER_STEP_ONE" | transloco }}</span>
      </div>
      <span class="subtitle">{{ "ROSTER_SUBTITLE" | transloco }}</span>
    </div>
    <img class="logo" src="assets/img/aau-logo.png" />
  </div>

  <div class="sub-header">
    <div class="f-row-20">
      <app-custom-material-input
        [label]="'SEARCH_ROSTER'"
        class="m-w-350"
        (valueChanged)="search($event)"
        [imageUrl]="'assets/icons/search.svg'"
      >
      </app-custom-material-input>

      <div class="ctx-menu-container">
        <button class="small-button" (click)="onShowFilterOptions()">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M5.33333 10H6.66667C7.03333 10 7.33333 9.7 7.33333 9.33333C7.33333 8.96667 7.03333 8.66667 6.66667 8.66667H5.33333C4.96667 8.66667 4.66667 8.96667 4.66667 9.33333C4.66667 9.7 4.96667 10 5.33333 10ZM0 2.66667C0 3.03333 0.3 3.33333 0.666667 3.33333H11.3333C11.7 3.33333 12 3.03333 12 2.66667C12 2.3 11.7 2 11.3333 2H0.666667C0.3 2 0 2.3 0 2.66667ZM2.66667 6.66667H9.33333C9.7 6.66667 10 6.36667 10 6C10 5.63333 9.7 5.33333 9.33333 5.33333H2.66667C2.3 5.33333 2 5.63333 2 6C2 6.36667 2.3 6.66667 2.66667 6.66667Z"
              fill="white"
            />
          </svg>
        </button>

        <div *ngIf="isContextMenuVisible" class="context-menu">
          <ul>
            <li
              *ngFor="let option of menuOptions"
              (click)="onContextMenuAction(option)"
              [class.active]="option.id === currentMenuOption?.id"
            >
              {{ option.name | transloco }}
            </li>
          </ul>
        </div>
      </div>
    </div>

    <button class="m-w-200 primary-button f-row-b">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="20"
        height="20"
        viewBox="0 0 20 20"
        fill="none"
      >
        <path
          d="M14.6875 3.7474H12.0833C12.0833 2.59831 11.1491 1.66406 10 1.66406C8.85091 1.66406 7.91667 2.59831 7.91667 3.7474H5.3125C4.44987 3.7474 3.75 4.44727 3.75 5.3099V16.7682C3.75 17.6309 4.44987 18.3307 5.3125 18.3307H14.6875C15.5501 18.3307 16.25 17.6309 16.25 16.7682V5.3099C16.25 4.44727 15.5501 3.7474 14.6875 3.7474ZM6.875 15.4661C6.44206 15.4661 6.09375 15.1178 6.09375 14.6849C6.09375 14.252 6.44206 13.9036 6.875 13.9036C7.30794 13.9036 7.65625 14.252 7.65625 14.6849C7.65625 15.1178 7.30794 15.4661 6.875 15.4661ZM6.875 12.3411C6.44206 12.3411 6.09375 11.9928 6.09375 11.5599C6.09375 11.127 6.44206 10.7786 6.875 10.7786C7.30794 10.7786 7.65625 11.127 7.65625 11.5599C7.65625 11.9928 7.30794 12.3411 6.875 12.3411ZM6.875 9.21615C6.44206 9.21615 6.09375 8.86784 6.09375 8.4349C6.09375 8.00195 6.44206 7.65365 6.875 7.65365C7.30794 7.65365 7.65625 8.00195 7.65625 8.4349C7.65625 8.86784 7.30794 9.21615 6.875 9.21615ZM10 2.96615C10.4329 2.96615 10.7812 3.31445 10.7812 3.7474C10.7812 4.18034 10.4329 4.52865 10 4.52865C9.56706 4.52865 9.21875 4.18034 9.21875 3.7474C9.21875 3.31445 9.56706 2.96615 10 2.96615ZM14.1667 14.9453C14.1667 15.0885 14.0495 15.2057 13.9062 15.2057H9.21875C9.07552 15.2057 8.95833 15.0885 8.95833 14.9453V14.4245C8.95833 14.2813 9.07552 14.1641 9.21875 14.1641H13.9062C14.0495 14.1641 14.1667 14.2813 14.1667 14.4245V14.9453ZM14.1667 11.8203C14.1667 11.9635 14.0495 12.0807 13.9062 12.0807H9.21875C9.07552 12.0807 8.95833 11.9635 8.95833 11.8203V11.2995C8.95833 11.1563 9.07552 11.0391 9.21875 11.0391H13.9062C14.0495 11.0391 14.1667 11.1563 14.1667 11.2995V11.8203ZM14.1667 8.69531C14.1667 8.83854 14.0495 8.95573 13.9062 8.95573H9.21875C9.07552 8.95573 8.95833 8.83854 8.95833 8.69531V8.17448C8.95833 8.03125 9.07552 7.91406 9.21875 7.91406H13.9062C14.0495 7.91406 14.1667 8.03125 14.1667 8.17448V8.69531Z"
          fill="white"
        />
      </svg>
      {{ "MEMBERSHIPS_FIND_AAU" | transloco }}
    </button>
  </div>

  <div
    class="athlete-card"
    *ngFor="
      let athlete of membersData?.items
        | paginate
          : {
              itemsPerPage: membersData?.size,
              currentPage: membersData?.page ? membersData.page + 1 : 1,
              totalItems: membersData?.total
            }
    "
  >
    <div class="c-card-header" (click)="expand(athlete)">
      <div class="name-and-age">
        <h2>{{ getName(athlete) }}</h2>
        <div class="f-row-6">
          <p>{{ athlete.gender | transloco }}</p>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="4"
            height="5"
            viewBox="0 0 4 5"
            fill="none"
          >
            <circle cx="2" cy="2.5" r="2" fill="#EEEEEE" />
          </svg>
          <p *ngIf="athlete.birthDate">
            {{ "AGE" | transloco }} {{ getAge(athlete) }}
          </p>
        </div>
      </div>

      <!-- Membership status -->
      <div class="c-membership">
        <div class="membership-status">
          <span>{{ "MEMBERSHIP_STATUS" | transloco }}</span>
          <span
            [class.active]="athlete.membershipStatus !== 'Expired'"
            [class.expired]="athlete.membershipStatus === 'Expired'"
          >
            {{
              athlete.membershipStatus ? athlete.membershipStatus : "Active"
            }}</span
          >
        </div>
        <label class="f-row-5">
          <svg
            class="icon1"
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
          >
            <path
              d="M9.16403 7.28902V10.3557C9.16403 10.6474 9.32236 10.9224 9.57236 11.0724L12.1724 12.614C12.4724 12.789 12.8557 12.689 13.0307 12.3974C13.2057 12.0974 13.114 11.714 12.814 11.539L10.4224 10.114V7.28069C10.414 6.94735 10.1307 6.66402 9.78903 6.66402C9.44736 6.66402 9.16403 6.94735 9.16403 7.28902ZM17.4974 7.91402V3.50569C17.4974 3.13069 17.0474 2.94735 16.789 3.21402L15.3057 4.69735C13.7974 3.18902 11.6474 2.32235 9.29736 2.53069C5.80569 2.84735 2.93069 5.65569 2.54736 9.14735C2.04736 13.664 5.57236 17.4974 9.99736 17.4974C13.8224 17.4974 16.9807 14.6307 17.439 10.9307C17.4974 10.4307 17.1057 9.99735 16.6057 9.99735C16.189 9.99735 15.839 10.3057 15.789 10.714C15.4307 13.6224 12.9224 15.8724 9.91403 15.8307C6.82236 15.789 4.21403 13.1807 4.16403 10.0807C4.11403 6.83069 6.75569 4.16402 9.99736 4.16402C11.6057 4.16402 13.064 4.82235 14.1224 5.87235L12.3807 7.61402C12.114 7.88069 12.2974 8.33069 12.6724 8.33069H17.0807C17.314 8.33069 17.4974 8.14735 17.4974 7.91402Z"
              fill="#1B1B1B"
            />
          </svg>
          <span class="auto-renew-text">{{
            "MEMBERSHIP_AUTOMATIC_RENEWAL" | transloco
          }}</span>
          <div
            *ngIf="athlete.autoRenew"
            [innerHTML]="IconSwitchOn | safe : SafeTypes.html"
          ></div>
          <div
            *ngIf="!athlete.autoRenew"
            [innerHTML]="IconSwitchOff | safe : SafeTypes.html"
          ></div>
        </label>
      </div>

      <div class="contact-info">
        <div>
          <strong>{{ "EMAIL:" | transloco }}</strong> {{ athlete.email }}
        </div>
        <div>
          <strong>{{ "PHONE:" | transloco }}</strong> {{ athlete.phoneNumber }}
        </div>
      </div>

      <div class="expand">
        <svg
          [class.rotate]="athlete.expanded"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M7.54639 11.8144L2.18557 6.4811C1.93814 6.20619 1.93814 5.79381 2.18557 5.54639L2.81787 4.91409C3.06529 4.66667 3.47766 4.66667 3.75258 4.91409L8.01375 9.14777L12.2474 4.91409C12.5223 4.66667 12.9347 4.66667 13.1821 4.91409L13.8144 5.54639C14.0619 5.79381 14.0619 6.20619 13.8144 6.4811L8.45361 11.8144C8.20619 12.0619 7.79381 12.0619 7.54639 11.8144Z"
            fill="#A3A3A3"
          />
        </svg>
      </div>
    </div>

    <div *ngIf="athlete.expanded" class="additional-details">
      <div class="ath-info">
        <img
          [src]="athlete.photoUrl ?? 'assets/img/individual-athelete-logo.png'"
          alt="Profile Photo"
          class="profile-photo"
        />

        <div class="details-section">
          <div class="details-block">
            <div *ngIf="athlete.birthDate" class="gap-8">
              <strong>{{ "DATE_OF_BIRTH" | transloco }}:</strong>
              <span>{{ athlete.birthDate | date : "MMM d, yyyy" }}</span>
            </div>
            <div *ngIf="athlete.country?.name" class="gap-8">
              <strong>{{ "COUNTRY" | transloco }}:</strong>
              <span>{{ athlete.country.name }}</span>
            </div>
            <div *ngIf="athlete.state?.name" class="gap-8">
              <strong>{{ "STATE" | transloco }}:</strong>
              <span>{{ athlete.state.name }}</span>
            </div>
          </div>

          <div class="details-block">
            <div *ngIf="athlete.address" class="gap-8">
              <strong>{{ "ADDRESS" | transloco }}:</strong>
              <span>{{ athlete.address }}</span>
            </div>
            <div *ngIf="athlete.city" class="gap-8">
              <strong>{{ "CITY" | transloco }}:</strong>
              <span>{{ athlete.city }}</span>
            </div>
            <div *ngIf="athlete.zipCode" class="gap-8">
              <strong>{{ "POSTAL_CODE" | transloco }}:</strong>
              <span>{{ athlete.zipCode }}</span>
            </div>
          </div>

          <div class="details-block">
            <div *ngIf="athlete.primarySport?.name" class="gap-8">
              <strong>{{ "SPORT" | transloco }}:</strong>
              <span> {{ athlete.primarySport?.name }}</span>
            </div>
            <div *ngIf="athlete.district" class="gap-8">
              <strong>{{ "DISTRICT" | transloco }}:</strong>
              <span>{{ athlete.district }}</span>
            </div>
            <div
              *ngIf="athlete.club?.name"
              (click)="copyToClipboard(athlete.club?.name)"
              class="clickable gap-8"
            >
              <strong>{{ "AAU_CLUB" | transloco }}:</strong>
              <span>{{ athlete.club.name }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
              >
                <path
                  d="M3.9375 0C3.31699 0 2.8125 0.504492 2.8125 1.125V5.0625C2.8125 5.68301 3.31699 6.1875 3.9375 6.1875H7.875C8.49551 6.1875 9 5.68301 9 5.0625V1.125C9 0.504492 8.49551 0 7.875 0H3.9375ZM1.125 2.8125C0.504492 2.8125 0 3.31699 0 3.9375V7.875C0 8.49551 0.504492 9 1.125 9H5.0625C5.68301 9 6.1875 8.49551 6.1875 7.875V6.75H5.0625V7.875H1.125V3.9375H2.25V2.8125H1.125Z"
                  fill="#0D6EFD"
                />
              </svg>
            </div>
            <div
              *ngIf="athlete.externalId"
              (click)="copyToClipboard(athlete.externalId)"
              class="clickable gap-8"
            >
              <strong>{{ "AAU_MEMBERSHIP_ID" | transloco }}:</strong>
              <span>{{ athlete.externalId }}</span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="9"
                height="9"
                viewBox="0 0 9 9"
                fill="none"
              >
                <path
                  d="M3.9375 0C3.31699 0 2.8125 0.504492 2.8125 1.125V5.0625C2.8125 5.68301 3.31699 6.1875 3.9375 6.1875H7.875C8.49551 6.1875 9 5.68301 9 5.0625V1.125C9 0.504492 8.49551 0 7.875 0H3.9375ZM1.125 2.8125C0.504492 2.8125 0 3.31699 0 3.9375V7.875C0 8.49551 0.504492 9 1.125 9H5.0625C5.68301 9 6.1875 8.49551 6.1875 7.875V6.75H5.0625V7.875H1.125V3.9375H2.25V2.8125H1.125Z"
                  fill="#0D6EFD"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>
      <div class="f-row-centered">
        <div class="f-col">
          <span class="share-ncsa">
            {{ "SHARE_INFO_WITH" | transloco }}
            <img class="ncsa" src="assets/img/NCSA.png" />
            <div
              *ngIf="athlete.isWantsNCSARecruiting"
              [innerHTML]="IconSwitchOn | safe : SafeTypes.html"
            ></div>
            <div
              *ngIf="!athlete.isWantsNCSARecruiting"
              [innerHTML]="IconSwitchOff | safe : SafeTypes.html"
            ></div>
          </span>
        </div>

        <button class="primary-button" (click)="onEdit(athlete)">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M11.0083 3.27971L13.4729 5.74425C13.5824 5.85379 13.5824 6.01809 13.4729 6.12763L7.50318 12.0973L4.95648 12.3711C4.62787 12.4259 4.32665 12.1247 4.38142 11.7961L4.65526 9.24939L10.6249 3.27971C10.7345 3.17017 10.8988 3.17017 11.0083 3.27971ZM15.4445 2.64988C15.8553 3.06064 15.8553 3.74523 15.4445 4.15599L14.4861 5.11443C14.3765 5.22396 14.2122 5.22396 14.1027 5.11443L11.6381 2.64988C11.5286 2.54034 11.5286 2.37604 11.6381 2.2665L12.5966 1.30807C13.0073 0.897311 13.6919 0.897311 14.1027 1.30807L15.4445 2.64988ZM10.5154 10.4817C10.5154 10.3721 10.5428 10.29 10.5976 10.2352L11.6929 9.13985C11.912 8.94817 12.268 9.08509 12.268 9.38631V13.6856C12.268 14.4249 11.6655 15 10.9535 15H1.31443C0.575061 15 0 14.4249 0 13.6856V4.04645C0 3.33447 0.575061 2.73203 1.31443 2.73203H9.11883C9.42005 2.73203 9.55697 3.08802 9.36528 3.30709L8.26993 4.40245C8.21516 4.45721 8.13301 4.4846 8.02347 4.4846H1.75257V13.2474H10.5154V10.4817Z"
              fill="white"
            />
          </svg>
          {{ "EDIT_INFO" | transloco }}
        </button>
      </div>
    </div>
  </div>
</div>

<app-pagination
  class="pag custom-margin"
  (pageChangeEvent)="onPaginate($event)"
></app-pagination>

<div class="end-buttons">
  <button class="primary-button" (click)="addAthlete()">
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M14.5575 5.34625L13.65 4.43875C13.065 3.85375 12.1125 3.85375 11.5275 4.43875L9.5175 6.44875L3 12.9663V15.9963H6.03L12.585 9.44125L14.5575 7.46875C15.15 6.88375 15.15 5.93125 14.5575 5.34625ZM5.4075 14.4963H4.5V13.5888L10.995 7.09375L11.9025 8.00125L5.4075 14.4963ZM9 15.9963L12 12.9963H16.5V15.9963H9Z"
        fill="white"
      />
    </svg>
    {{ "ADD_NEW_ATHLETE" | transloco }}
  </button>
  <button class="success-button" (click)="goToMembershipsStep()">
    {{ "NEXT_STEP" | transloco }}
  </button>
</div>
