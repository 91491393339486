import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  OnDestroy,
  OnInit,
} from "@angular/core";
import { LocalizationModule } from "src/app/components/localization";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { BottomBannerComponent } from "src/app/components/bottom-banner/bottom-banner.component";
import { Select, Store } from "@ngxs/store";
import { EventsService } from "src/app/shared/constants/events.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { UserState } from "src/app/store/user/user.store";
import { firstValueFrom, map, Observable, skip, Subscription } from "rxjs";
import { User } from "src/app/shared/models/user.model";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { RegistrationAthleteComponent } from "../registration-athlete/registration-athlete.component";
import { ApiService } from "src/app/shared/constants/api.service";
import { CustomMaterialInputComponent } from "src/app/components/custom-material-input/custom-material-input.component";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrService } from "ngx-toastr";
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../shared/constants/safe-types.enum";

import { IconSwitchOff } from "src/assets/icons/switch-off";
import { IconSwitchOn } from "src/assets/icons/switch-on";
import { OrderSummaryComponent } from "../registration/order-summary/order-summary.component";
import { getAge, getName } from "src/app/utils/membership-utils";

@Component({
  selector: "app-roster-athletes",
  templateUrl: "./roster-athletes.component.html",
  standalone: true,
  imports: [
    LocalizationModule,
    NgFor,
    NgIf,
    BottomBannerComponent,
    NgClass,
    SafePipe,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    RegistrationAthleteComponent,
    CustomMaterialInputComponent,
    NgxPaginationModule,

    PaginationComponent,
  ],
  styleUrls: ["./roster-athletes.component.scss"],
})
export class RosterAthletesComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  public SafeTypes = SafeTypes;
  public IconSwitchOff = IconSwitchOff;
  public IconSwitchOn = IconSwitchOn;
  isContextMenuVisible: boolean = false;
  currentMenuOption: any = undefined;
  menuOptions = [
    { id: "1", name: "SORT_A_Z", direction: "ASC", property: "firstName" },
    { id: "2", name: "SORT_Z_A", direction: "DESC", property: "firstName" },
    { id: "3", name: "SORT_DATE_NEWEST", direction: "DESC", property: "date" },
    { id: "4", name: "SORT_DATE_OLDEST", direction: "ASC", property: "date" },
  ];

  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  user: User | undefined | null;

  subscriptions: Subscription[] = [];
  athletes: any;
  searchString: string = "";
  pageSize = 10;
  pageIndex = 0;
  membersAmount!: number;
  public membersData: any;
  selectedCount: number = 0;
  eventId: number = 0;

  constructor(
    private eventsService: EventsService,
    private store: Store,
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute
  ) {}

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.authUser$.subscribe(async (user: any) => {
        if (user) {
          this.user = user;

          this.updateFilters();
          // const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
          // if (!isAdmin) {
          //   this.router.navigateByUrl("/");
          // }
        } else {
          // this.router.navigateByUrl("/");
        }
      })
    );
  }

  @HostListener("document:click", ["$event"])
  closeContextMenu(event: Event) {
    if (!(event.target as HTMLElement).closest(".small-button")) {
      this.isContextMenuVisible = false;
    }
  }

  async filterMembers(
    search: string,
    pageIndex: number,
    pageSize: number,
    orders?: any
  ) {
    try {
      const data = await firstValueFrom(
        this.api.getPersons(search, pageIndex, pageSize, orders)
      );
      this.membersData = data;
    } catch (e) {
      console.error(e);
    }
  }

  search(input: string) {
    this.searchString = input?.trim();
    this.updateFilters();
  }

  onPaginate(page: any) {
    this.pageIndex = page > 0 ? page - 1 : 0;
    this.updateFilters();
  }

  ngOnInit(): void {
    this.eventId = +this.route.snapshot.params["eventId"];
    if (!this.eventId) {
      // TODO: shoudl I go back?
    }
    // this.filterMembers(this.searchString);
    // this.fetchPaidOrders();
    // this.fetchActiveOrders();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
  }

  expand(athlete: any) {
    if (athlete.expanded) {
      athlete.expanded = false;
    } else {
      athlete.expanded = true;
    }
  }

  onEdit(athlete: any) {
    this.api.editingPerson = athlete;
    this.router.navigateByUrl(
      `roster/${this.eventId}/edit-athlete/${athlete.id}`
    );
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value).then(() => {
      this.toastr.success("Copied to clipboard!");
    });
  }

  addAthlete() {
    this.router.navigateByUrl(`roster/${this.eventId}/new-athlete`);
  }

  goToMembershipsStep() {
    this.router.navigateByUrl(`roster/${this.eventId}/memberships`);
  }

  getName(athlete: any): string {
    return getName(athlete);
  }

  getAge(athlete: any): number | null {
    return getAge(athlete);
  }

  updateFilters() {
    this.filterMembers(
      this.searchString,
      this.pageIndex,
      this.pageSize,
      this.getFilterOrder(this.currentMenuOption)
    );
  }

  onShowFilterOptions() {
    this.isContextMenuVisible = !this.isContextMenuVisible;
  }

  showContextMenu() {
    this.isContextMenuVisible = true;
  }

  hideContextMenu() {
    this.isContextMenuVisible = false;
  }

  onContextMenuAction(option: any) {
    if (this.currentMenuOption === option) {
      this.currentMenuOption = undefined;
    } else {
      this.currentMenuOption = option;
    }

    this.hideContextMenu();

    this.updateFilters();
  }

  getFilterOrder(option: any) {
    if (option) {
      return {
        orders: [{ direction: option.direction, property: option.property }],
      };
    }

    return undefined;
  }
}
