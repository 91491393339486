import {SupportedLocales} from "../app/shared/constants/supported-locales";

export const environment = {
  production: false,
  linkAndroidApp: 'https://play.google.com/store/apps/details?id=com.uventex.studio',
  linkIOSApp: 'https://apps.apple.com/app/myuventex-studio-management/id1465602154',
  //PRIVACY_POLICY_LINK: 'http://devuventex.com/GWTClient/html/privacyPolicy.html',
  PRIVACY_POLICY_LINK: new Map([
    [
      SupportedLocales.en,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.en.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.es,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.es.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.fr,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.fr.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.ja,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.ja.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.ru,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.ru.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
    [
      SupportedLocales.ua,
      `https://uventexlabs.zendesk.com/hc/${SupportedLocales.ua.toLocaleLowerCase()}/articles/360043332751-Privacy-Policy`,
    ],
  ]),
  policiesLinks:[
    { key: 'privacy', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=42bc4e43-e5ad-42ee-a2b6-f3c55fd6c105', route: '/privacypolicy', label: 'POLICY_PRIVACY' },
    { key: 'cookies', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=b8af1ade-4d24-4dcd-b33c-7b4274d9ee39', route: '/cookies', label: 'POLICY_COOKIE' },
    { key: 'terms', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=8a25df7e-116b-4486-b72b-5fd063bcf0fb', route: '/termsandconditions', label: 'POLICY_TERMS_AND_COND' },
    { key: 'endUser', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=47e0048f-6b0d-421f-840d-99ec1b593bdd', route: '/enduser', label: 'POLICY_EULA' },
    { key: 'return', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=153412b6-67e9-4a4a-905f-97f71c6426a0', route: '/returnpolicy', label: 'POLICY_RETURN' },
    { key: 'disclaimer', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=237d80bb-e700-4e59-9e4a-30b10c8add81', route: '/disclaimer', label: 'POLICY_DISCLAIMER' },
    { key: 'shipping', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=5af5f1eb-41c1-43dc-8a02-4cab7c32eb0a', route: '/shipping', label: 'POLICY_SHIPPING' },
    { key: 'acceptableUse', external: 'https://app.termly.io/policy-viewer/policy.html?policyUUID=c93cf174-b303-4d09-b061-229bfdde6717', route: '/acceptable', label: 'POLICY_ACCEPTABLE' },
  ],
  contactPhoneNumber: '+1 469-766-2095',
  contactEmail: 'support@uventex.com',
  studioApi: 'https://api.studio.demouventex.com/',
  studioPortalUrl: 'https://demouventex.com/studios/',
  STACK_PAY_ENV: 'sandbox',
  APIURL: 'https://sandbox-api.uventex.com/',
  BASEURL: 'https://demouventex.com/',
  CURRENT_URL: 'https://demouventex.com/',
  EVENT_ADMIN_URL: 'https://admin-uventex.demouventex.com/',
  EVENT_PORTAL_URL: 'https://portal.demouventex.com/',
  EVENT_REPORT_URL: 'https://report.demouventex.com/',
  FORTE_JS: '',
  googleRecapchaKey: '6LeKMG0aAAAAACeQrosUaX45lQos1RKG2sdQHfng',
  YOUTUBE: 'https://www.googleapis.com/',
  youtubeChannelId: 'UULF5pnyfSfNG6altM86pxtB3w',
  youtubeAPIKEY: 'AIzaSyCUKVYr1f48g08KJyCciqwTS6HQkhVl2fk',
  devUvLink: 'https://demouventex.com/'
};
