<!--<div class="loader" *ngIf="loaderService.isLoading$ | async">
    <div class="loader-spinner">
        <img src="../../../../assets/img/loader.svg" alt="" />
    </div>
</div>-->
<div id="event" *ngIf="event | async">
  <div class="event-navigation">
    <div class="breadcrumbs">
      <a routerLink="/events">
        <i class="fa-solid fa-house"></i>
      </a>
      <i class="fa-solid fa-circle"></i>
      <a routerLink="/events">
        <span>{{ "EVENTS" | transloco }}</span>
      </a>
    </div>

    <div class="nav-title">
      <div class="arrow" (click)="navigationService.back()">
        <i class="fa-solid fa-arrow-left"></i>
      </div>

      <div class="event-title">
        <span>{{ (event | async)?.name }}</span>
        <a
          class="share"
          [cdkCopyToClipboard]="clipBoardValue"
          (click)="onCopy()"
        >
          <span>{{ "COPY_LINK" | transloco }}</span>
          <i class="fa-solid fa-link"></i>
          <div
            class="copied"
            *ngIf="isCopied"
            class="copied"
            [class.visible]="isCopied"
          >
            {{ "COPIED" | transloco }}
          </div>
        </a>
      </div>
    </div>

    <div class="event-menu" *ngIf="menu?.length > 0">
      <ng-container *ngFor="let item of menu">
        <a
          *ngIf="item.type !== MenuItemType.statistics"
          class="menu-option"
          [ngClass]="{ active: activeMenuItem === item.type }"
          (click)="activeMenuItem = item.type"
          [href]="item.url"
        >
          <span>{{ item.name | transloco }}</span>
        </a>
        <a
          class="menu-option"
          *ngIf="item.type === MenuItemType.statistics"
          (click)="$event.stopPropagation()"
          [matMenuTriggerFor]="statisticsMenu"
          [matMenuTriggerData]="{ item: item }"
        >
          <span>{{ item.name | transloco }}</span>
        </a>
      </ng-container>

      <mat-menu
        #statisticsMenu="matMenu"
        xPosition="after"
        yPosition="below"
        class="statistics-menu"
      >
        <ng-template matMenuContent let-item="item">
          <div class="menu-items">
            <a
              *ngFor="let subItem of item.subMenu; let i = index"
              [href]="subItem.url"
            >
              <span class="item-title">{{ subItem.name | transloco }}</span>
            </a>
          </div>
        </ng-template>
      </mat-menu>
    </div>
  </div>
  <div class="wrap-event">
    <div class="event">
      <div class="event-details">
        <div class="event-date">
          <div class="event-period">
            <div class="event-period-days">
              <span class="title">{{ "EVENT_DATES" | transloco }}</span>
              <span class="amount">{{
                eventsService.checkSameDates(
                  (event | async)?.eventStartDate,
                  (event | async)?.eventEndDate
                )
              }}</span>
            </div>
            <div class="event-period_registration_days-left">
              <span class="title">{{ "DAYS_LEFT" | transloco }}</span>
              <span class="amount">{{ daysLeft >= 0 ? daysLeft : 0 }}</span>
            </div>
          </div>
        </div>

        <div class="event-info">
          <div class="event-info-days">
            <div class="event-info-days__registartion-days">
              <span class="title">{{ "REGISTRATION_DAYS" | transloco }}</span>
              <span class="amount">{{
                moment((event | async)?.regEndDate).format("MMM DD, YYYY")
              }}</span>
            </div>
          </div>

          <div class="event-info__action-buttons">
            <button
              *ngIf="currentEvent?.type !== 'TICKETS'"
              class="register-now"
              type="button"
              (click)="handleRegisterNow()"
              [disabled]="!checkRegistrationEnabled(event | async)"
            >
              {{ "REGISTER_NOW" | transloco }}
            </button>
            <button
              *ngIf="(event | async)?.eventConfig?.isAllowedToBuyTicket"
              class="buy-tickets"
              (click)="handleBuyTickets()"
            >
              {{ "BUY_TICKETS" | transloco }}
            </button>
          </div>
          <!-- <div class="event-info__action-buttons">
            <button class="buy-tickets" (click)="handleRegisterAsOfficial()">
              {{ "REGISTER_AS_OFFICIAL" | transloco }}
            </button>
          </div> -->
        </div>

        <div
          class="line-info"
          *ngIf="currentEvent?.venue?.name || getLocation(currentEvent?.venue)"
        >
          <img src="/assets/icons/location.svg" class="info-icon" />

          <div class="location-line">
            <span class="location-name">{{
              (event | async)?.venue?.name
            }}</span>
            <a
              class="info-content"
              target="_blank"
              [href]="getMapHref((event | async)?.venue)"
              (click)="preventNavigation($event, currentEvent?.venue)"
              >{{ getLocation((event | async)?.venue) }}
            </a>
          </div>

          <a
            *ngIf="(event | async)?.venue"
            class="copy"
            target="_blank"
            (click)="copyLocationToClipBoard()"
          >
            {{ "COPY" | transloco }}
          </a>
        </div>

        <div
          *ngIf="(currentEvent?.phoneArray || []).length > 0"
          class="line-info"
        >
          <img src="/assets/icons/phone.svg" class="info-icon" />

          <div>
            <div *ngFor="let phone of currentEvent?.phoneArray">
              <a class="info-content" href="tel:{{ phone }}" target="_blank">
                {{ phone }}
              </a>

              <a class="copy" target="_blank" (click)="copyToClipBoard(phone)">
                {{ "COPY" | transloco }}
              </a>
            </div>
          </div>
        </div>

        <div class="event-date">
          <div
            *ngIf="(currentEvent?.emailArray || []).length > 0"
            class="line-info"
          >
            <img src="/assets/icons/email.svg" class="info-icon" />

            <div>
              <div *ngFor="let email of currentEvent?.emailArray">
                <a
                  target="_blank"
                  class="info-content"
                  href="mailto:{{ email }}?subject=Event:%20{{
                    currentEvent?.name
                  }}"
                >
                  {{ email }}
                </a>
                <a
                  class="copy"
                  target="_blank"
                  (click)="copyToClipBoard(email)"
                >
                  {{ "COPY" | transloco }}
                </a>
              </div>
            </div>
          </div>
        </div>

        <div class="public-files">
          <div
            *ngFor="let p of currentEvent?.publicFiles"
            class="line-info-alt"
            (click)="navigateToDownload(p.downloadLink.url)"
          >
            <div class="info-icon-alt">
              <img
                src="/assets/icons/description.svg"
                class="public-files-img"
              />
            </div>
            <span class="download-link">{{ p.name }}</span>
            <img src="/assets/icons/download.svg" />
          </div>
        </div>

        <div class="description" *ngIf="(event | async)?.info">
          <span class="description__title">{{ "ABOUT" | transloco }}</span>
          <div>
            <span
              class="description__info"
              [innerHTML]="(event | async)?.info | safe : SafeTypes.html"
            ></span>
          </div>
        </div>
      </div>

      <div class="wrap-carousel">
        <div
          class="event-gallery__featured"
          [ngClass]="{
            onlyImage: !(processedImages && processedImages.length > 1)
          }"
        >
          <owl-carousel-o
            [options]="customOptions"
            #owlCar
            (changed)="changedByNav($event)"
          >
            <ng-container *ngFor="let image of processedImages">
              <ng-template carouselSlide [id]="image.url">
                <img
                  #owlCarBanner
                  [src]="image.url"
                  [ngClass]="getAspectRatioClass(image)"
                  (click)="
                    openImg({ url: image.url, banners: processedImages })
                  "
                />
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>

        <div
          class="event-gallery__carousel"
          *ngIf="processedImages && processedImages.length > 1"
        >
          <owl-carousel-o
            [options]="{ dots: false, margin: 5, autoWidth: true }"
          >
            <ng-container *ngFor="let image of processedImages; let i = index">
              <ng-template carouselSlide [id]="image.url" [width]="80">
                <a (click)="owlCar.to(image.url); changeImage(image.url)">
                  <img
                    class="max-width-heigth"
                    [src]="image.url"
                    [ngClass]="{
                      'event-gallery__carousel-item--active':
                        selectedImage === image.url ||
                        (i == 0 && !selectedImage)
                    }"
                  />
                </a>
              </ng-template>
            </ng-container>
          </owl-carousel-o>
        </div>
      </div>
    </div>
  </div>
</div>
