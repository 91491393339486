import { Injectable, NgZone } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from "../../../environments/environment";
import { Venue } from '../models/venue.model';
import { ToastrService } from 'ngx-toastr';
import { MenuItemType } from 'src/app/pages/home/event/event.component';
import * as moment from 'moment';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class EventsService {
    baseUrl: string = environment.APIURL;
    gridItemWidthandHeigth$:BehaviorSubject<any> = new BehaviorSubject(null);

    constructor(
        private http: HttpClient,
        private zone: NgZone,
        private toaster: ToastrService
    ) {}

    getEventsList(params?: any) {
        const path = 'api/public/events/filter';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }
    getEventsListCustomSort(params?: any) {
        const path = 'api/public/events/filter/dashboard';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }

    getEvent(id: number) {
      const path = `api/public/events/${id}`;
        return this.http
            .get<Venue[]>(`${this.baseUrl}${path}`)
    }

    getVenueFilter(venueName: string) {
        const path = 'api/v1/integrations/aau/venue-filter';
        const charNum = 10;
        return this.http
            .get<Venue[]>(`${environment.devUvLink}${path}?filter=${venueName}&limit=${charNum}`)
    }

    checkSubdomain(subdomainName: string) {
        const path = 'api/v1/integrations/aau/check-subdomain';
        return this.http
            .get<any>(`${environment.devUvLink}${path}?subdomain=${subdomainName}`)
    }

    

    copyEvent(eventCopyInfo: any) {
        const path = 'api/v1/integrations/aau/copy-event';
        return this.http.post(`${environment.devUvLink}${path}`, eventCopyInfo)
    }

    getGeocode(address: string) {
        const addressStr = address.split(' ').join('+');
        const key = 'AIzaSyA9SO3tPbXBHlapgNdeaMa6O6Edf-cRZZM';
        const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${addressStr}&key=${key}`;
        return this.http.get(googleUrl);
    }

    getReverseGeocode(latlng: string) {
        const key = 'AIzaSyA9SO3tPbXBHlapgNdeaMa6O6Edf-cRZZM';
        const googleUrl = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${latlng}&key=${key}`;
        return this.http.get(googleUrl);
    }

    getTopSportsList() {
        const path = 'api/public/events/top-sports';
        return this.http.get(`${this.baseUrl}${path}`);
    }

    getTopLeaguesList() {
        const path = 'api/public/events/top-leagues';
        return this.http.get(`${this.baseUrl}${path}`);
    }

    getTopCountriesList() {
        const path = 'api/public/events/top-counties';
        return this.http.get(`${this.baseUrl}${path}`);
    }

    getFilteredSportsList(name: string = '') {
        const params = {
            filter: {
                name: name,
                excludeIds: null,
            },
            page: 0,
            size: 1000,
        };

        const path = 'api/public/events/filter/sports';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }

    getFilteredLeaguesList(name: string = '') {
        const params = {
            filter: {
                name: name,
                excludeIds: null,
            },
            page: 0,
            size: 1000,
        };

        const path = 'api/public/events/filter/leagues';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }

    getFilteredCountriesList(name: string = '') {
        const params = {
            filter: {
                name: name,
                excludeIds: null,
            },
            page: 0,
            size: 1000,
        };

        const path = 'api/public/events/filter/counties';
        return this.http.post(`${this.baseUrl}${path}`, params);
    }

    removeAllEventScores(id: number) {
        return this.http.delete(`${environment.EVENT_ADMIN_URL}api/v1/event/delete-all-division-scores?eventId=${id}`);
    }

    initMenu(eventId: any, superEventId:any, configItems: any, user:any, authToken:any, returnUrl:string, isAdmin: boolean): any {
        if (!configItems) {
          return null;
        }

        const accessLevel = user?.eventAccount?.accessLevel;
        const roleList = user?.eventAccount?.roleList;
        const hasEventRoles = roleList && roleList.length > 0;
        const isPromoter = accessLevel === "PROMOTER";
        const isCoordinator = accessLevel === "COORDINATOR";

        const hasLeaguePrivileges = this.doesUserHaveLeaguePrivileges(user);
        const allowedEventIds = user?.eventAccount?.allowedEventIds;
    
        const token =
          authToken && authToken.token ? ";token=" + authToken.token : "";
        const sportsHubMode = `;applicationMode=SPORTSHUB;sportsHubUrl=${returnUrl}`;
    
        const urlWithoutAdmin = environment.EVENT_PORTAL_URL.replace(
          "admin-uventex.",
          ""
        ).replace("admin.", "");
    
        const standingsCondition =
          isAdmin ||
          (isPromoter && allowedEventIds && allowedEventIds.includes(eventId)) ||
          isCoordinator;
    
        const condition = isAdmin || hasEventRoles || hasLeaguePrivileges;
        const pricingUrl = `${
          condition ? environment.EVENT_ADMIN_URL : urlWithoutAdmin
        }#divisionPricing;id=${superEventId};eventType=SuperEvent${sportsHubMode}${
          condition ? token : ""
        }`;
        const medalsUrl = `${
          condition ? environment.EVENT_ADMIN_URL : urlWithoutAdmin
        }#medalsReport;id=${eventId}${sportsHubMode}${condition ? token : ""}`;
        const flowsUrl = `${
          condition ? environment.EVENT_ADMIN_URL : urlWithoutAdmin
        }#flowsSparring;id=${eventId}${sportsHubMode}${condition ? token : ""}`;
        const liveScoringUrl = `${
          condition ? environment.EVENT_ADMIN_URL : urlWithoutAdmin
        }#ringScoring;id=${eventId}${sportsHubMode}${condition ? token : ""}`;
        const timeTableUrl = `${
          condition ? environment.EVENT_PORTAL_URL : urlWithoutAdmin
        }digital/timetable/${eventId}?ringIds=&time=0:0${condition ? token : ""}`;
        const bracketsUrl = `${environment.EVENT_REPORT_URL}#brackets;id=${eventId}${sportsHubMode}`;
        const standingsUrl = `${
          standingsCondition
            ? environment.EVENT_ADMIN_URL
            : environment.EVENT_REPORT_URL
        }#standings;id=${eventId}${sportsHubMode}${
          standingsCondition ? token : ""
        }`;
    
        const menu = [];
    
        if (!configItems["DISABLE_DIVISIONS_PRICING"]) {
          menu.push({
            name: "PRICING",
            type: MenuItemType.pricing,
            url: pricingUrl,
          });
        }
    
        if (configItems["PUBLIC_BRACKETS_REPORT"]) {
          menu.push({
            name: "SCHEDULE",
            type: MenuItemType.schedule,
            url: bracketsUrl,
          });
        }
    
        if (configItems["PUBLIC_MEDALS_REPORT"]) {
          menu.push({
            name: "MEDALS",
            type: MenuItemType.medals,
            url: medalsUrl,
          });
        }
    
        if (configItems["PUBLIC_STANDINGS_REPORT"]) {
          menu.push({
            name: "STANDINGS",
            type: MenuItemType.standings,
            url: standingsUrl,
          });
        }
    
        if (configItems["SPARRING_FLOWS"]) {
          menu.push({
            name: "FLOWS",
            type: MenuItemType.flows,
            url: flowsUrl,
          });
        }
    
        if (configItems["PUBLIC_RINGS_SCORING"]) {
          menu.push({
            name: "LIVE_SCORE",
            type: MenuItemType.liveScore,
            url: liveScoringUrl,
          });
        }
    
        if (configItems["PUBLIC_TIME_TABLE"]) {
          menu.push({
            name: "TIMETABLE",
            type: MenuItemType.timetable,
            url: timeTableUrl,
          });
        }
    
        if (configItems["PUBLIC_COMPETITORS_REPORT"]) {
          menu.push({
            name: "COMPETITORS",
            type: MenuItemType.competitors,
            url: `${environment.EVENT_REPORT_URL}#competitors;id=${eventId}${sportsHubMode}`,
          });
        }
    
        if (configItems["PUBLIC_STATISTICS_REPORT"]) {
          menu.push({
            name: "STATISTICS",
            type: MenuItemType.statistics,
            subMenu: [
              {
                name: "ALL_ENTRIES",
                url: `${environment.EVENT_REPORT_URL}#competitors;id=${eventId};reportType=statisticsEntries${sportsHubMode}`,
              },
              {
                name: "ENTRIES_IN_DIVISIONS",
                url: `${environment.EVENT_REPORT_URL}#competitors;id=${eventId};reportType=statisticsCategories${sportsHubMode}`,
              },
              {
                name: "ENTRIES_FROM_TEAMS",
                url: `${environment.EVENT_REPORT_URL}#competitors;id=${eventId};reportType=statisticsTeams${sportsHubMode}`,
              },
              {
                name: "ENTRIES_FROM_COUNTRIES",
                url: `${environment.EVENT_REPORT_URL}#competitors;id=${eventId};reportType=statisticsCountries${sportsHubMode}`,
              },
            ],
          });
        }
    
        if (menu.length > 0) {
          menu.unshift({
            name: "EVENT",
            type: MenuItemType.event,
            url: `events/event/${eventId}`,
          });
        }

        return menu;
    
    }

    doesUserHaveLeaguePrivileges(user: any) {
        let result = false;
        if (user && user.eventAccount && user.eventAccount.roleList) {
          const roleList = user.eventAccount.roleList;
          if (roleList.length > 0) {
            const length = roleList.length;
            for (let i = 0; i < length; i++) {
              const role = roleList[i];
              if (role.hasLeaguePrivileges === true) {
                result = true;
                break;
              }
            }
          }
        }
        return result;
    }

    public checkSameDates(startDateValue: string | undefined, endDateValue: string | undefined): string {
      if (startDateValue && endDateValue) {
        const startDate = moment.utc(startDateValue);
        const endDate = moment.utc(endDateValue);

        if (startDateValue === endDateValue) {
          return moment.utc(startDateValue).format('ll');
        } else if (startDate.month() === endDate.month() && startDate.year() === endDate.year()) {
          return `${moment.utc(startDateValue).format('MMM DD')} - ${moment.utc(endDateValue).format('DD, YYYY')}`
        } else if (startDate.month() !== endDate.month() && startDate.year() === endDate.year()) {
          return `${moment.utc(startDateValue).format('MMM DD')} - ${moment.utc(endDateValue).format('ll')}`
        } else {
          return `${moment.utc(startDateValue).format('ll')} - ${moment.utc(endDateValue).format('ll')}`
        }
      } else {
        return ''
      }
    }
}
