import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
  ViewChild,
} from "@angular/core";
import { MatSidenavModule } from "@angular/material/sidenav";
import { MatListModule } from "@angular/material/list";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { DatePipe, NgClass, NgFor, NgIf } from "@angular/common";
import { MatCardModule } from "@angular/material/card";
import { MatSelectModule } from "@angular/material/select";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { LocalizationModule } from "src/app/components/localization";

@Component({
  selector: "app-custom-date-picker",
  templateUrl: "./custom-date-picker.component.html",
  standalone: true,
  imports: [
    MatSidenavModule,
    NgFor,
    MatSidenavModule,
    MatListModule,
    MatFormFieldModule,
    MatInputModule,
    NgFor,
    NgIf,
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    MatCardModule,
    MatSelectModule,
    LocalizationModule,
    DatePipe,
  ],
  styleUrls: ["./custom-date-picker.component.scss"],
})
export class CustomDatePickerComponent {
  isOpen = false;
  currentStep: "year" | "month" | "day" = "year";

  years: number[] = [];
  months = [
    "MONTH_SHORT_1",
    "MONTH_SHORT_2",
    "MONTH_SHORT_3",
    "MONTH_SHORT_4",
    "MONTH_SHORT_5",
    "MONTH_SHORT_6",
    "MONTH_SHORT_7",
    "MONTH_SHORT_8",
    "MONTH_SHORT_9",
    "MONTH_SHORT_10",
    "MONTH_SHORT_11",
    "MONTH_SHORT_12",
  ];
  days: number[] = [];

  selectedYear?: number;
  selectedMonth?: number;
  selectedDay?: number;

  lastYear?: number;
  lastMonth?: number;
  @Input() selectedDate?: Date;
  partialDate?: string;

  @Output() dateSelected = new EventEmitter<Date>();

  @Input() submitted = false;
  @Input() error: string | undefined;

  @ViewChild("entireComponent", { static: false })
  entireComponent!: ElementRef;

  constructor() {
    this.generateYears();
  }

  generateYears() {
    const currentYear = new Date().getFullYear();
    const minYear = new Date(currentYear - 100, 0, 1).getFullYear();
    const maxYear = new Date(currentYear - 2, 11, 31).getFullYear();
    for (let year = maxYear; year >= minYear; year--) {
      this.years.push(year);
    }
  }

  @HostListener("document:click", ["$event"])
  onClickOutside(event: MouseEvent) {
    if (!this.entireComponent?.nativeElement) {
      return;
    }

    const clickedInside = this.entireComponent?.nativeElement.contains(
      event.target
    );
    if (!clickedInside && this.isOpen) {
      this.partialDate = "";
      this.selectedYear = this.lastYear;
      this.selectedMonth = this.lastMonth;
      this.toggleDropdown();
    }
  }

  toggleDropdown() {
    this.isOpen = !this.isOpen;
    if (this.isOpen) this.currentStep = "year";
  }

  selectYear(year: number) {
    setTimeout(() => {
      this.lastYear = this.selectedYear;
      this.selectedYear = year;
      this.partialDate = "" + year;
      this.currentStep = "month";
    });
  }

  selectMonth(month: number, event: any) {
    setTimeout(() => {
      const textMonth = event?.target?.textContent?.trim() ?? "";
      this.lastMonth = this.selectedMonth;
      this.selectedMonth = month;
      this.partialDate = textMonth + " " + this.selectedYear;
      this.currentStep = "day";
      this.generateDays();
    });
  }

  generateDays() {
    if (this.selectedYear === undefined || this.selectedMonth === undefined)
      return;

    const daysInMonth = new Date(
      this.selectedYear,
      this.selectedMonth + 1,
      0
    ).getDate();
    this.days = Array.from({ length: daysInMonth }, (_, i) => i + 1);
  }

  selectDay(day: number) {
    setTimeout(() => {
      this.selectedDay = day;
      this.partialDate = "";
      this.selectedDate = new Date(
        this.selectedYear!,
        this.selectedMonth!,
        day
      );
      this.dateSelected.emit(this.selectedDate);
      this.isOpen = false;
    });
  }
}
