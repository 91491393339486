import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { LocalizationModule } from "src/app/components/localization";
import { CommonModule, NgClass, NgFor, NgIf } from "@angular/common";
import { BottomBannerComponent } from "src/app/components/bottom-banner/bottom-banner.component";
import { Select, Store } from "@ngxs/store";
import { EventsService } from "src/app/shared/constants/events.service";
import { ActivatedRoute, Router, RouterModule } from "@angular/router";
import { MatIconModule } from "@angular/material/icon";
import { UserState } from "src/app/store/user/user.store";
import { firstValueFrom, map, Observable, skip, Subscription } from "rxjs";
import { User } from "src/app/shared/models/user.model";
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from "@angular/forms";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatNativeDateModule } from "@angular/material/core";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatRadioModule } from "@angular/material/radio";
import { RegistrationAthleteComponent } from "../registration-athlete/registration-athlete.component";
import { ApiService } from "src/app/shared/constants/api.service";
import { CustomMaterialInputComponent } from "src/app/components/custom-material-input/custom-material-input.component";
import { PaginationComponent } from "../../components/pagination/pagination.component";
import { NgxPaginationModule } from "ngx-pagination";
import { ToastrService } from "ngx-toastr";
import { SafePipe } from "../../shared/pipes/safe.pipe";
import { SafeTypes } from "../../shared/constants/safe-types.enum";
import { getAge, getName } from "src/app/utils/membership-utils";

@Component({
  selector: "app-roster-memberships",
  templateUrl: "./roster-memberships.component.html",
  standalone: true,
  imports: [
    LocalizationModule,
    NgFor,
    NgIf,
    NgClass,
    SafePipe,
    RouterModule,
    MatIconModule,
    MatInputModule,
    MatSelectModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatButtonModule,
    MatCheckboxModule,
    MatRadioModule,
    CommonModule,
    CustomMaterialInputComponent,
    NgxPaginationModule,
  ],
  styleUrls: ["./roster-memberships.component.scss"],
})
export class RosterMembershipsComponent
  implements OnInit, OnDestroy, AfterViewInit
{
  @Select(UserState.authUser)
  public authUser$!: Observable<User>;

  @Select(UserState.isAdmin)
  isAdmin$!: Observable<boolean>;

  user: User | undefined | null;

  subscriptions: Subscription[] = [];
  athletes: any;
  pageSize = 1000;
  pageIndex = 0;
  membersAmount!: number;
  public membersData: any;
  selectedCount: number = 0;
  eventId: number = 0;

  terms = `Membership in the AAU is a privilege granted by the AAU. It is not a right. The AAU at its sole discretion reserves the right to accept or reject any applicant(s) for membership.<br><br>Membership in any category may be granted only after an application is submitted and approved. By submitting an application, the applicant agrees to comply with all the provisions of the <u>AAU Code, including its constitution, bylaws, policies, procedures, regulations, and rules*</u>.<br><br> I certify that I have the athlete's parent's or guardian's consent for the athlete to become an AAU Member.<br><br>* I accept all terms and conditions for this AAU membership application as laid out by the AAU code book (available here) and this application.<br><br>* I hereby certify that all information I have provided is accurate, my name (below) is correct, and I am authorized to apply for membership for the youths in this application.<br><br>* I understand that there are no refunds issued for cancellations.<br><br>Membership must always be paid and member number issued prior to participation in any activity (including practices and try-outs)`;
  agreed = false;

  signatureForm!: FormGroup;

  memberships = [
    {
      id: 1,
      title: "Extended benefit",
      titleNote: "(AB) Coverage",
      selectedAthletes: 0,
      note1:
        "This membership permits participation in non-AAU events. For team competitions, all members, coaches, and staff need AAU extended coverage. In individual events, each participant must have extended coverage and be supervised by a registered AAU coach.",
      note2: "*Please note that memberships are non-refundable once issued.",
      expanded: false,
      totalPrice: 0,
    },
    {
      id: 2,
      title: "Standard coverage",
      titleNote: "(AB) Coverage",
      selectedAthletes: 0,
      note1:
        "A standard coverage membership is required for athletes and team members to participate in licensed AAU events.",
      note2: "*Please note that memberships are non-refundable once issued.",
      expanded: false,
      totalPrice: 0,
    },
  ];
  submitted = false;

  constructor(
    private api: ApiService,
    private router: Router,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private fb: FormBuilder
  ) {
    this.signatureForm = this.fb.group({
      firstName: ["", Validators.required],
      lastName: ["", Validators.required],
    });
  }

  updateForm(formInput: string, value: string) {
    const control = this.signatureForm.get(formInput);
    if (control) {
      control.setValue(value);
      control.markAsTouched();
    }
  }

  updateFormWithEvent(inputValue: string, event: Event) {
    const inputElement = event.target as HTMLInputElement;
    this.updateForm(inputValue, inputElement.value);
  }

  selectAth(
    ath: any,
    membership: {
      id: number;
      title: string;
      titleNote: string;
      selectedAthletes: number;
      note1: string;
      note2: string;
      expanded: boolean;
      totalPrice: number;
    },
    select: boolean
  ) {
    if (select) {
      for (const m of this.memberships) {
        this.selectAth(ath, m, false);
      }
      membership.selectedAthletes++;
      membership.totalPrice += 22;
      ath.selectedMembershipId = membership.id;
    } else {
      if (
        ath.selectedMembershipId &&
        ath.selectedMembershipId === membership.id
      ) {
        membership.selectedAthletes--;
        membership.totalPrice -= 22;
        ath.selectedMembershipId = null;
      }
    }
  }

  ngAfterViewInit(): void {
    this.subscriptions.push(
      this.authUser$.subscribe(async (user: any) => {
        if (user) {
          this.user = user;

          this.filterMembers("", this.pageIndex, this.pageSize);

          // const isAdmin = this.store.selectSnapshot(UserState.isAdmin);
          // if (!isAdmin) {
          //   this.router.navigateByUrl("/");
          // }
        } else {
          // this.router.navigateByUrl("/");
        }
      })
    );
  }

  async filterMembers(search: string, pageIndex: number, pageSize: number) {
    try {
      const data = await firstValueFrom(
        this.api.getPersons(search, pageIndex, pageSize)
      );
      this.membersData = data;
    } catch (e) {
      console.error(e);
    }
  }

  ngOnInit(): void {
    this.eventId = +this.route.snapshot.params["eventId"];
    if (!this.eventId) {
      // TODO: shoudl I go back?
    }
    // this.filterMembers(this.searchString);
    // this.fetchPaidOrders();
    // this.fetchActiveOrders();
  }

  ngOnDestroy(): void {
    for (const s of this.subscriptions) {
      try {
        s.unsubscribe();
      } catch (e) {
        console.error(e);
      }
    }
  }

  expand(athlete: any) {
    if (athlete.expanded) {
      athlete.expanded = false;
    } else {
      athlete.expanded = true;
    }
  }

  onEdit(athlete: any) {
    this.api.editingPerson = athlete;
    this.router.navigateByUrl(
      `roster/${this.eventId}/edit-athlete/${athlete.id}`
    );
  }

  copyToClipboard(value: string) {
    navigator.clipboard.writeText(value).then(() => {
      this.toastr.success("Copied to clipboard!");
    });
  }

  addAthlete() {
    this.router.navigateByUrl(`roster/${this.eventId}/new-athlete`);
  }

  goToMembershipsStep() {
    this.router.navigateByUrl(`roster/${this.eventId}/memberships`);
  }

  getName(athlete: any): string {
    return getName(athlete);
  }

  getAge(athlete: any): number | null {
    return getAge(athlete);
  }
}
