<div class="custom-component-theme dropdown-container">
  <button
    class="dropdown-button"
    (click)="toggleDropdown(); $event.stopPropagation()"
    type="button"
  >
    <span class="custom-floating-label" *ngIf="selectedDate || partialDate">{{
      "DATE_OF_BIRTH" | transloco
    }}</span>
    <div class="c-row">
      <span [ngClass]="{ 'text-input': !(selectedDate || partialDate) }">
        {{
          selectedDate || partialDate
            ? partialDate
              ? partialDate
              : (selectedDate | date : "MMM dd, yyyy")
            : ("DATE_OF_BIRTH" | transloco)
        }}
      </span>

      <span class="icons-container">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="10"
          viewBox="0 0 9 10"
          fill="none"
          class="icon"
        >
          <path
            d="M7.32187 1.41708H7.13437V0.958541C7.13437 0.67982 6.9375 0.5 6.65625 0.5C6.375 0.5 6.17813 0.67982 6.17813 0.958541V1.41708H3.06563V0.958541C3.06563 0.67982 2.87812 0.5 2.5875 0.5C2.29688 0.5 2.10938 0.67982 2.10938 0.958541V1.41708H1.67812C0.76875 1.41708 0 2.15435 0 3.02647V7.89061C0 8.76274 0.76875 9.5 1.67812 9.5H7.32187C8.23125 9.5 9 8.76274 9 7.89061V3.02647C9 2.15435 8.23125 1.41708 7.32187 1.41708ZM1.67812 2.33417H7.32187C7.70625 2.33417 8.04375 2.65784 8.04375 3.02647V3.66484H0.95625V3.02647C0.95625 2.65784 1.29375 2.33417 1.67812 2.33417ZM7.32187 8.57393H1.67812C1.29375 8.57393 0.95625 8.25025 0.95625 7.88162V4.58192H8.03437V7.88162C8.04375 8.25025 7.70625 8.57393 7.32187 8.57393Z"
            fill="var(--customizable-primary-color)"
          />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="9"
          height="10"
          viewBox="0 0 9 10"
          fill="none"
          class="icon chevron"
          [ngClass]="{ rotate: isOpen }"
        >
          <path
            d="M4.84021 7.3724L8.86082 3.7051C9.04639 3.51607 9.04639 3.23251 8.86082 3.06238L8.3866 2.6276C8.20103 2.45747 7.89175 2.45747 7.68557 2.6276L4.48969 5.53875L1.31443 2.6276C1.10825 2.45747 0.798969 2.45747 0.613402 2.6276L0.139175 3.06238C-0.0463918 3.23251 -0.0463918 3.51607 0.139175 3.7051L4.15979 7.3724C4.34536 7.54253 4.65464 7.54253 4.84021 7.3724Z"
            fill="#7A7A7A"
          />
        </svg>
      </span>
    </div>
  </button>

  <mat-error *ngIf="submitted && !selectedDate">{{
    "REQUIRED" | transloco
  }}</mat-error>

  <mat-error *ngIf="error">{{ error }}</mat-error>

  <div class="dropdown-content" *ngIf="isOpen" #entireComponent>
    <!-- Year Selection -->
    <div class="year-grid" *ngIf="currentStep === 'year'">
      <div class="grid-container">
        <div
          *ngFor="let year of years"
          class="grid-item year"
          [class.selected]="year === selectedYear"
          (click)="selectYear(year)"
        >
          {{ year }}
        </div>
      </div>
    </div>

    <!-- Month Selection -->
    <div class="month-grid" *ngIf="currentStep === 'month'">
      <div class="grid-container">
        <div
          *ngFor="let month of months; let i = index"
          class="grid-item"
          [class.selected]="i === selectedMonth"
          (click)="selectMonth(i, $event)"
        >
          {{ month | transloco }}
        </div>
      </div>
    </div>

    <!-- Day Selection -->
    <div class="day-grid" *ngIf="currentStep === 'day'">
      <div class="grid-container">
        <div
          *ngFor="let day of days"
          class="grid-item"
          [class.selected]="day === selectedDay"
          (click)="selectDay(day)"
        >
          {{ day }}
        </div>
      </div>
    </div>
  </div>
</div>
