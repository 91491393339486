<div
  class="custom-sselect custom-component-theme"
  #searchDopDownList
  [ngClass]="{ errorc: error }"
>
  <div class="selected-item" (click)="toggleDropdown()">
    <div class="c-label-input" [ngClass]="{ errorc: error }">
      <span
        class="custom-floating-label"
        *ngIf="selectedItem"
        [ngClass]="{ errorc: error }"
        >{{ label | transloco }}</span
      >

      <div
        class="c-label"
        [class.open]="dropdownOpen"
        *ngIf="!selectedItem"
        [ngClass]="{ errorc: error }"
      >
        {{ label | transloco }}
      </div>

      <div class="main-title" *ngIf="selectedItem" [class.open]="dropdownOpen">
        <span [class.f-row-j]="selectedItem.imageUrl">
          <img
            *ngIf="selectedItem.imageUrl"
            class="country-flag1"
            [src]="selectedItem.imageUrl"
          />
          <div
            class="row-spans"
            *ngIf="
              selectedItem.membershipId &&
              selectedItem.name &&
              selectedItem.zipCode
            "
          >
            <span class="span1">{{ selectedItem.membershipId }}</span>
            <span class="span2">{{ selectedItem.name }}</span>
            <span class="span3">{{ selectedItem.zipCode }}</span>
          </div>
          <div
            *ngIf="
              !(
                selectedItem.membershipId &&
                selectedItem.name &&
                selectedItem.zipCode
              )
            "
          >
            {{
              selectedItem?.imageUrl ? selectedItem.phonePrefix : selectedItem
            }}
          </div>
        </span>
      </div>
    </div>

    <svg
      matSuffix
      xmlns="http://www.w3.org/2000/svg"
      class="icon"
      [class.rotate]="dropdownOpen"
      [ngClass]="{ errorc: error }"
      width="9"
      height="10"
      viewBox="0 0 9 10"
      fill="none"
    >
      <path
        d="M4.84021 7.3724L8.86082 3.7051C9.04639 3.51607 9.04639 3.23251 8.86082 3.06238L8.3866 2.6276C8.20103 2.45747 7.89175 2.45747 7.68557 2.6276L4.48969 5.53875L1.31443 2.6276C1.10825 2.45747 0.798969 2.45747 0.613402 2.6276L0.139175 3.06238C-0.0463918 3.23251 -0.0463918 3.51607 0.139175 3.7051L4.15979 7.3724C4.34536 7.54253 4.65464 7.54253 4.84021 7.3724Z"
        fill="#7A7A7A"
      />
    </svg>
  </div>

  <!-- Dropdown Section -->
  <div class="dropdown" [class.open]="dropdownOpen">
    <input
      #searchInput
      type="text"
      class="search-input"
      placeholder="{{ 'SEARCH_...' | transloco }}"
      [(ngModel)]="searchTerm"
      (input)="filter()"
    />
    <div class="dropdown-list" *ngIf="filteredItems">
      <div
        *ngFor="let item of filteredItems"
        class="dropdown-item"
        [class.selected]="selectedItem === item"
        (click)="selectItem(item)"
      >
        <div class="item">
          <div
            *ngIf="item.membershipId && item.name && item.zipCode"
            class="row-spans"
          >
            <span class="span1">{{ item.membershipId }}</span>
            <span class="span2">{{ item.name }}</span>
            <span class="span3">{{ item.zipCode }}</span>
          </div>
          <div class="item" *ngIf="!(item.membershipId && item.name && item.zipCode)">
            <img
              *ngIf="item.imageUrl"
              class="country-flag1"
              [src]="item.imageUrl"
            />{{ item?.imageUrl ? item.phonePrefix : item }}
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
