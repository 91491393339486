<div class="c-pad custom-component-theme">
  <div class="header">
    <div class="left-header">
      <div class="title-section">
        <svg
          width="20"
          height="19"
          viewBox="0 0 20 19"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M18.125 18.25H1.875C0.820312 18.25 0 17.4297 0 16.375V2.625C0 1.60938 0.820312 0.75 1.875 0.75H18.125C19.1406 0.75 20 1.60938 20 2.625V16.375C20 17.4297 19.1406 18.25 18.125 18.25ZM5 4.1875C4.10156 4.1875 3.4375 4.89062 3.4375 5.75C3.4375 6.64844 4.10156 7.3125 5 7.3125C5.85938 7.3125 6.5625 6.64844 6.5625 5.75C6.5625 4.89062 5.85938 4.1875 5 4.1875ZM5 7.9375C4.10156 7.9375 3.4375 8.64062 3.4375 9.5C3.4375 10.3984 4.10156 11.0625 5 11.0625C5.85938 11.0625 6.5625 10.3984 6.5625 9.5C6.5625 8.64062 5.85938 7.9375 5 7.9375ZM5 11.6875C4.10156 11.6875 3.4375 12.3906 3.4375 13.25C3.4375 14.1484 4.10156 14.8125 5 14.8125C5.85938 14.8125 6.5625 14.1484 6.5625 13.25C6.5625 12.3906 5.85938 11.6875 5 11.6875ZM16.25 6.375V5.125C16.25 4.89062 16.0156 4.65625 15.7812 4.65625H7.96875C7.69531 4.65625 7.5 4.89062 7.5 5.125V6.375C7.5 6.64844 7.69531 6.84375 7.96875 6.84375H15.7812C16.0156 6.84375 16.25 6.64844 16.25 6.375ZM16.25 10.125V8.875C16.25 8.64062 16.0156 8.40625 15.7812 8.40625H7.96875C7.69531 8.40625 7.5 8.64062 7.5 8.875V10.125C7.5 10.3984 7.69531 10.5938 7.96875 10.5938H15.7812C16.0156 10.5938 16.25 10.3984 16.25 10.125ZM16.25 13.875V12.625C16.25 12.3906 16.0156 12.1562 15.7812 12.1562H7.96875C7.69531 12.1562 7.5 12.3906 7.5 12.625V13.875C7.5 14.1484 7.69531 14.3438 7.96875 14.3438H15.7812C16.0156 14.3438 16.25 14.1484 16.25 13.875Z"
            fill="var(--customizable-primary-color)"
          />
        </svg>

        <span class="title">{{ "NAV_ROSTER_STEP_TWO" | transloco }}</span>
      </div>
      <span class="subtitle">{{ "MEMBERSHIPS_SUBTITLE" | transloco }}</span>
    </div>
    <img class="logo" src="assets/img/aau-logo.png" />
  </div>

  <div class="m-card" *ngFor="let m of memberships">
    <div class="c-card-header" (click)="expand(m)">
      <div class="info">
        <div class="m-title-section">
          <div class="m-title">
            <span>{{ m.title }}</span>
            <span *ngIf="m.titleNote">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="4"
                height="4"
                viewBox="0 0 4 4"
                fill="none"
              >
                <circle cx="2" cy="2" r="2" fill="#1B1B1B" />
              </svg>
            </span>
            <span *ngIf="m.titleNote">{{ m.titleNote }}</span>
          </div>
          <div
            class="ath-selected"
            [class.selected-blue]="m.selectedAthletes > 0"
          >
            {{ m.selectedAthletes }} {{ "ATHLETES_SELECTED" | transloco }}
          </div>
        </div>

        <span class="note">{{ m.note1 }}</span>
        <span class="note">{{ m.note2 }}</span>
      </div>
      <div class="price">
        <span>USD {{ m.totalPrice | number : "1.2-2" }}</span>
      </div>
      <div class="expand">
        <svg
          [class.rotate]="m.expanded"
          xmlns="http://www.w3.org/2000/svg"
          width="16"
          height="16"
          viewBox="0 0 16 16"
          fill="none"
        >
          <path
            d="M7.54639 11.8144L2.18557 6.4811C1.93814 6.20619 1.93814 5.79381 2.18557 5.54639L2.81787 4.91409C3.06529 4.66667 3.47766 4.66667 3.75258 4.91409L8.01375 9.14777L12.2474 4.91409C12.5223 4.66667 12.9347 4.66667 13.1821 4.91409L13.8144 5.54639C14.0619 5.79381 14.0619 6.20619 13.8144 6.4811L8.45361 11.8144C8.20619 12.0619 7.79381 12.0619 7.54639 11.8144Z"
            fill="#A3A3A3"
          />
        </svg>
      </div>
    </div>

    <div *ngIf="m.expanded" class="athletes">
      <div *ngFor="let ath of membersData.items">
        <div
          class="ath-header"
          [class.ath-selected-row]="
            ath.selectedMembershipId && ath.selectedMembershipId === m.id
          "
        >
          <div class="f-row">
            <span class="ath-name">{{ getName(ath) }}</span>
            <span class="ath-data">{{ ath.gender | transloco }}</span>
            <span *ngIf="ath.birthDate" class="ath-data">{{
              getAge(ath)
            }}</span>
          </div>
          <div class="f-row">
            <span class="ath-price">USD 22.00</span>
            <button
              class="small-primary-btn"
              (click)="selectAth(ath, m, true)"
              *ngIf="
                !ath.selectedMembershipId || ath.selectedMembershipId !== m.id
              "
            >
              Select athlete
            </button>
            <div
              class="unselect-container"
              *ngIf="
                ath.selectedMembershipId && ath.selectedMembershipId === m.id
              "
            >
              <span class="added">
                Added
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="17"
                  height="16"
                  viewBox="0 0 17 16"
                  fill="none"
                >
                  <path
                    d="M5.45026 13.7644L0.235602 8.54974C-0.078534 8.2356 -0.078534 7.70157 0.235602 7.38743L1.36649 6.25654C1.68063 5.94241 2.18325 5.94241 2.49738 6.25654L6.04712 9.77487L13.5864 2.2356C13.9005 1.92147 14.4031 1.92147 14.7173 2.2356L15.8482 3.36649C16.1623 3.68063 16.1623 4.21466 15.8482 4.5288L6.61257 13.7644C6.29843 14.0785 5.7644 14.0785 5.45026 13.7644Z"
                    fill="#7A7A7A"
                  />
                </svg>
              </span>
              <span
                class="delete-ath-selection"
                (click)="selectAth(ath, m, false)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                >
                  <path
                    d="M13.8125 1.875C14.0312 1.875 14.25 2.09375 14.25 2.3125V3.1875C14.25 3.43359 14.0312 3.625 13.8125 3.625H2.4375C2.19141 3.625 2 3.43359 2 3.1875V2.3125C2 2.09375 2.19141 1.875 2.4375 1.875H5.71875L5.96484 1.38281C6.07422 1.16406 6.29297 1 6.53906 1H9.68359C9.92969 1 10.1484 1.16406 10.2578 1.38281L10.5312 1.875H13.8125ZM3.44922 13.7695L2.875 4.5H13.375L12.7734 13.7695C12.7461 14.4805 12.1719 15 11.4609 15H4.76172C4.05078 15 3.47656 14.4805 3.44922 13.7695Z"
                    fill="#E15F5F"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="terms">
    <span class="terms-title">Terms and conditions</span>
    <span class="terms-descr">Review and sign</span>
    <span class="terms-note">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M15.5768 13.0572C16.0693 13.9328 15.4399 15 14.4275 15H1.32027C0.307808 15 -0.321557 13.9055 0.17099 13.0572L6.73828 1.64651C7.23083 0.77087 8.51692 0.798233 9.00947 1.64651L15.5768 13.0572ZM7.88756 10.6765C7.1761 10.6765 6.62883 11.2512 6.62883 11.9353C6.62883 12.6467 7.1761 13.194 7.88756 13.194C8.57165 13.194 9.14629 12.6467 9.14629 11.9353C9.14629 11.2512 8.57165 10.6765 7.88756 10.6765ZM6.68355 6.16152L6.8751 9.88299C6.90246 10.0745 7.03928 10.184 7.20346 10.184H8.54429C8.70847 10.184 8.84529 10.0745 8.87265 9.88299L9.0642 6.16152C9.09156 5.96997 8.92738 5.80579 8.73583 5.80579H7.01192C6.82037 5.80579 6.65619 5.96997 6.68355 6.16152Z"
          fill="#FFC123"
        />
      </svg>
      You must read through terms and conditions before proceeding
    </span>

    <div class="border-terms">
      <div
        class="inner-border-terms"
        [innerHTML]="terms"
        [class.terms-success]="agreed"
      ></div>
    </div>

    <mat-checkbox [(ngModel)]="agreed" class="agree">
      I have read and agree to Terms and conditions
    </mat-checkbox>
  </div>

  <div class="digital-signature" [class.disabled]="!agreed">
    <span class="digital-title" [class.disabled]="!agreed">
      Digital Signature
    </span>
    <span class="terms-note" [class.disabled]="!agreed">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M15.5768 13.0572C16.0693 13.9328 15.4399 15 14.4275 15H1.32027C0.307808 15 -0.321557 13.9055 0.17099 13.0572L6.73828 1.64651C7.23083 0.77087 8.51692 0.798233 9.00947 1.64651L15.5768 13.0572ZM7.88756 10.6765C7.1761 10.6765 6.62883 11.2512 6.62883 11.9353C6.62883 12.6467 7.1761 13.194 7.88756 13.194C8.57165 13.194 9.14629 12.6467 9.14629 11.9353C9.14629 11.2512 8.57165 10.6765 7.88756 10.6765ZM6.68355 6.16152L6.8751 9.88299C6.90246 10.0745 7.03928 10.184 7.20346 10.184H8.54429C8.70847 10.184 8.84529 10.0745 8.87265 9.88299L9.0642 6.16152C9.09156 5.96997 8.92738 5.80579 8.73583 5.80579H7.01192C6.82037 5.80579 6.65619 5.96997 6.68355 6.16152Z"
          fill="#FFC123"
        />
      </svg>
      Non-Youth: This must be signed by the person applying for membership
    </span>
    <span class="terms-note" [class.disabled]="!agreed">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="16"
        height="16"
        viewBox="0 0 16 16"
        fill="none"
      >
        <path
          d="M15.5768 13.0572C16.0693 13.9328 15.4399 15 14.4275 15H1.32027C0.307808 15 -0.321557 13.9055 0.17099 13.0572L6.73828 1.64651C7.23083 0.77087 8.51692 0.798233 9.00947 1.64651L15.5768 13.0572ZM7.88756 10.6765C7.1761 10.6765 6.62883 11.2512 6.62883 11.9353C6.62883 12.6467 7.1761 13.194 7.88756 13.194C8.57165 13.194 9.14629 12.6467 9.14629 11.9353C9.14629 11.2512 8.57165 10.6765 7.88756 10.6765ZM6.68355 6.16152L6.8751 9.88299C6.90246 10.0745 7.03928 10.184 7.20346 10.184H8.54429C8.70847 10.184 8.84529 10.0745 8.87265 9.88299L9.0642 6.16152C9.09156 5.96997 8.92738 5.80579 8.73583 5.80579H7.01192C6.82037 5.80579 6.65619 5.96997 6.68355 6.16152Z"
          fill="#FFC123"
        />
      </svg>
      Youth: This must be signed by the parentally approved representative
    </span>

    <form
      [formGroup]="signatureForm"
      class="form-name"
      [class.disabled]="!agreed"
    >
      <mat-form-field appearance="fill" class="m-w-225">
        <mat-label>{{ "FIRST_NAME" | transloco }}</mat-label>
        <input
          matInput
          formControlName="firstName"
          (input)="updateFormWithEvent('firstName', $event)"
        />
        <mat-error
          *ngIf="
            signatureForm.get('firstName')?.hasError('required') &&
            (signatureForm.get('firstName')?.touched || submitted)
          "
        >
          {{ "REQUIRED" | transloco }}
        </mat-error>
      </mat-form-field>

      <mat-form-field appearance="fill" class="m-w-225">
        <mat-label>{{ "LAST_NAME" | transloco }}</mat-label>
        <input
          matInput
          formControlName="lastName"
          (input)="updateFormWithEvent('lastName', $event)"
        />
        <mat-error
          *ngIf="
            signatureForm.get('lastName')?.hasError('required') &&
            (signatureForm.get('lastName')?.touched || submitted)
          "
        >
          {{ "REQUIRED" | transloco }}
        </mat-error>
      </mat-form-field>
    </form>
  </div>

  <div class="end-buttons">
    <div></div>
    <button
      class="success-button"
      (click)="goToMembershipsStep()"
      [disabled]="!signatureForm.valid || !agreed"
    >
      {{ "NEXT_STEP" | transloco }}
    </button>
  </div>
</div>
